import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Toast } from "react-toastify/dist/components";

import Blueprint from "models/resources/blueprint.model";
import apiClient from "services/api";
import { getDataById } from "services/requests/listData";

const useBlueprintDetails = () => {
  const { t } = useTranslation();

  const { id } = useParams();
  const [blueprintData, setBlueprintData] = useState<Blueprint | null>(null);
  const [fetch, refetch] = useState<boolean>(false); // Used for requesting after signal update.
  const deleteSignalMapping = async (
    machine_blueprint_id: number,
    {
      row,
      column,
    }: {
      row: number;
      column: number;
    },
  ) => {
    try {
      await apiClient({
        url: `machine-blueprints/${machine_blueprint_id}/signal-mapping/${row}/${column}`,
        method: "delete",
      });
      refetch((old) => !old);
    } catch (error) {
      toast.error(
        t(
          "Cannot delete a signal while it is connected to a button with a product",
        ),
      );
    }
  };

  const createSignalMapping = async ({
    row,
    column,
    value,
  }: {
    row: number;
    column: number;
    value: string;
  }) => {
    const schemeId = blueprintData?.machine_blueprint_signal_mappings.find(
      (mb) => mb.column === column && mb.row === row,
    )?.id;

    let bodyData = {
      button_signal: value,
      row,
      column,
      id: schemeId,
    };

    if (schemeId === undefined) delete bodyData.id;

    await apiClient<{
      id: number;
      button_signal: string;
      row: number;
      column: number;
      machine_blueprint_id: number;
    }>({
      url: `machine-blueprints/${id}/signal-mapping`,
      method: "post",
      bodyData,
    }).then(() => refetch((old) => !old));
  };

  const updateSignalMapping = async ({
    row,
    column,
    value,
  }: {
    row: number;
    column: number;
    value: string;
  }) => {
    const schemeId = blueprintData?.machine_blueprint_signal_mappings.find(
      (mb) => mb.column === column && mb.row === row,
    )?.id;

    let bodyData = {
      button_signal: value,
      id: schemeId,
    };

    if (schemeId === undefined) delete bodyData.id;

    await apiClient<{
      id: number;
      button_signal: string;
      machine_blueprint_id: number;
    }>({
      url: `machine-blueprints/${id}/signal-mapping/${schemeId}`,
      method: "patch",
      bodyData,
    }).then(() => refetch((old) => !old));
  };

  useEffect(() => {
    if (id) {
      getDataById(setBlueprintData, `machine-blueprints/${id}`);
    }
  }, [id, fetch]);

  return {
    blueprintData,
    setBlueprintData,
    createSignalMapping,
    updateSignalMapping,
    deleteSignalMapping,
  };
};

export default useBlueprintDetails;
