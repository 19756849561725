import { Fragment } from "react";
import { useTranslation } from "react-i18next";

import { CustomDetailsPage } from "components/DetailsPage/DetailsPage";
import MappingScheme from "components/MappingScheme";
import ContentLayout from "components/layouts/ContentLayout";

import MappingBlueprintScheme from "./MappingBlueprintScheme";
import useBlueprintDetails from "./useBlueprintDetails";
import { createSchemeMatrix } from "./utils";

const BlueprintDetails = () => {
  const { t } = useTranslation();
  const { blueprintData, setBlueprintData, createSignalMapping } =
    useBlueprintDetails();
  const fields = [
    {
      value: blueprintData?.name,
      label: "Name",
      type: "InfoText",
      show: true,
    },
    {
      value:
        blueprintData?.company?.display_name || blueprintData?.company?.name,
      id: blueprintData?.company_id,
      label: "Company",
      type: "Link",
      route: "companies",
      show: true,
    },
    {
      value: blueprintData?.number_of_rows,
      label: "Number of rows",
      type: "InfoText",
      show: true,
    },
    {
      value: blueprintData?.number_of_columns,
      label: "Number of columns",
      type: "InfoText",
      show: true,
    },
    {
      type: "CustomComponent",
      value: (
        <Fragment>
          <h1
            style={{
              margin: "auto",
              width: "50%",
              padding: "10px",
              textAlign: "center",
            }}
          >
            {t("Scheme")}
          </h1>
          {/* <MappingScheme
            label="Button signal"
            rowsCount={blueprintData?.number_of_rows ?? 0}
            columnsCount={blueprintData?.number_of_columns ?? 0}
            signalMatrix={createSchemeMatrix(
              maxRows,
              maxColumns,
              blueprintData?.machine_blueprint_signal_mappings.map((sm) => ({
                row: sm.row,
                column: sm.column,
                value: sm.button_signal,
              })) ?? [],
            )}
            onSubmit={createSignalMapping}
          /> */}
          <MappingBlueprintScheme />
        </Fragment>
      ),
      label: "",
      show: true,
    },
  ];

  return (
    <ContentLayout
      title={`${t("Blueprints")} > ${blueprintData?.name ?? ""}`}
      filters={false}
    >
      <CustomDetailsPage
        data={blueprintData}
        setData={setBlueprintData}
        resource={"blueprint"}
        call={"machine-blueprints"}
        fields={fields}
        //remove
        isEditable={false}
      />
    </ContentLayout>
  );
};

export default BlueprintDetails;
